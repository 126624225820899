<template>
    <div class="inquiry_popup modal1" @click="hideSetBoxMo()">
        <UpdateComment v-if="showUpdateComment" :musicId="musicId" :preContent="content" @closeModal="showUpdateComment = false" @closeModal2="getComment()" />
        <RemoveComment v-if="showRemoveComment" :musicNm="music.musicNm" @closeModal="showRemoveComment = false" @removeComment="removeMusicComment()" />
        <div class="music_comment modal_box">
            <div class="music_comment_title modal_title">
                <h2>한줄평</h2>
                <div class="modal_close_btn" @click="$emit('closeModal')">
                    <img src="/media/images/icon_close_24.svg" alt="" />
                </div>
                <div class="mo_space_header">
                    <div class="mo_header_box">
                        <h4 style="margin-bottom: 0px">한줄평</h4>
                        <div class="btn_close">
                            <img src="/media/images/icon_close_24.svg" @click="$emit('closeModal')" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="music_comment_music_info">
                <div class="music_comment_album">
                    <img :src="music.albumImg" />
                </div>
                <div class="music_comment_musicNm_artist">
                    <p class="music_comment_musicNm" v-html="music.musicNm"></p>
                    <p class="music_comment_artist" v-html="music.artist"></p>
                </div>
            </div>
            <div class="comment_content" v-if="!updateComment">
                <textarea type="text" maxlength="50" placeholder="이 노래의 한줄평을 남겨보세요:)" v-model="content" @click="textareaClickEvent($event)"></textarea>
                <p class="comment_length">{{ content.length }}<span>/50</span></p>
                <button class="comment_btn_cancel" @click="insertTextBoxOff($event)">취소</button>
                <button class="comment_btn" :class="{ disabled: disabledInsert === true }" v-if="content.length > 0" @click="insertMusicComment($event)">등록</button>
                <button class="comment_btn" style="text-align: center; filter: brightness(30%)" v-else>등록</button>
            </div>
            <div class="comment_content" v-else>
                <div class="written_comment" v-if="!isUpdateComment">
                    <p v-html="content"></p>
                    <div class="setting_area">
                        <div id="setting_icon" @click="showSetBox($event)">
                            <img src="/media/img/setting_icon.svg" class="show_select_box" />
                        </div>
                        <div class="setting_show_box">
                            <p @click="updateTextBoxOn($event)"><img src="/media/img/icon_update.svg" alt="" />수정</p>
                            <p @click="showRemoveComment = true"><img src="/media/img/icon_delete_16px.svg" alt="" />삭제</p>
                        </div>
                        <div class="mo_setting_show_box">
                            <p @click="updateTextBoxOn($event)"><img src="/media/img/icon_update.svg" alt="" />수정하기</p>
                            <p @click="showRemoveComment = true"><img src="/media/img/icon_delete_16px.svg" alt="" />삭제하기</p>
                        </div>
                    </div>
                </div>
                <textarea v-else type="text" maxlength="50" v-model="content" @keyup="keyEvent()"></textarea>
                <p class="comment_length">{{ content.length }}<span>/50</span></p>
                <span class="comment_btn_cancel" @click="updateTextBoxOff($event)">취소</span>
                <span class="comment_btn" v-if="!disabledUpdate" @click="updateMusicComment($event)">수정</span>
                <span class="comment_btn" style="text-align: center; filter: brightness(30%)" v-else>수정</span>
            </div>
            <ul class="music_comment_list" v-if="total < 1">
                <li class="music_comment_empty">
                    <p>아직 작성된 한줄평이 없어요.</p>
                    <p>이 노래의 첫 한줄평을 작성해주세요:)</p>
                </li>
            </ul>
            <div class="music_comment_list_box" v-else>
                <ul class="music_comment_list" v-for="(item, index) in musicCommentList" :key="index">
                    <li>
                        <span>
                            <span v-if="item.gender === '1' || item.gender === 'M' || item.gender === 'male'">
                                <img v-if="item.age === '10-19' || item.age === '10~19' || checkAge(item.birthDate) < 20" src="/media/img/visitor/men_age10.svg" width="18" height="18" />
                                <img v-else-if="item.age === '20-29' || item.age === '20~29' || (checkAge(item.birthDate) < 30 && checkAge(item.birthDate) >= 20)" src="/media/img/visitor/men_age20.svg" width="18" height="18" />
                                <img v-else-if="item.age === '30-39' || item.age === '30~39' || (checkAge(item.birthDate) < 40 && checkAge(item.birthDate) >= 30)" src="/media/img/visitor/men_age30.svg" width="18" height="18" />
                                <img v-else-if="item.age === '40-49' || item.age === '40~49' || (checkAge(item.birthDate) < 50 && checkAge(item.birthDate) >= 40)" src="/media/img/visitor/men_age40.svg" width="18" height="18" />
                                <img v-else src="/media/img/visitor/men_age50.svg" width="18" height="18" />
                            </span>
                            <span v-else>
                                <img v-if="item.age === '10-19' || item.age === '10~19' || checkAge(item.birthDate) < 20" src="/media/img/visitor/women_age10.svg" width="18" height="18" />
                                <img v-else-if="item.age === '20-29' || item.age === '20~29' || (checkAge(item.birthDate) < 30 && checkAge(item.birthDate) >= 20)" src="/media/img/visitor/women_age20.svg" width="18" height="18" />
                                <img v-else-if="item.age === '30-39' || item.age === '30~39' || (checkAge(item.birthDate) < 40 && checkAge(item.birthDate) >= 30)" src="/media/img/visitor/women_age30.svg" width="18" height="18" />
                                <img v-else-if="item.age === '40-49' || item.age === '40~49' || (checkAge(item.birthDate) < 50 && checkAge(item.birthDate) >= 40)" src="/media/img/visitor/women_age40.svg" width="18" height="18" />
                                <img v-else src="/media/img/visitor/women_age50.svg" width="18" height="18" />
                            </span>
                            <img class="social_icon" v-if="item.comeFrom === 'kakao'" src="/media/img/visitor/icon_cmt_kakao.svg" width="10" />
                            <img class="social_icon" v-else-if="item.comeFrom === 'naver'" src="/media/img/visitor/icon_cmt_naver.svg" width="10" />
                            <p class="music_comment_writer">{{ item.email }}</p>
                        </span>
                        <p class="music_comment_content" v-html="item.content"></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import UpdateComment from '@/components/modal/Player/UpdateComment.vue';
import RemoveComment from '@/components/modal/Player/RemoveComment.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import $ from 'jquery';

export default defineComponent({
  name: 'MusicComment',
  data () {
    return {
      musicCommentList: [],
      music: {},
      musicId: '',
      content: '',
      total: 0,
      limit: 10,
      offset: 0,
      disabledInsert: false,
      disabledUpdate: false,
      updateComment: false,
      isUpdateComment: false,
      showUpdateComment: false,
      showRemoveComment: false
    };
  },
  props: {
    musicInfo: Object
  },
  components: {
    UpdateComment,
    RemoveComment
  },
  async mounted () {
    this.music = this.musicInfo;
    await this.getMusicCommentList();
    await this.getMusicCommentByUser();
  },
  methods: {
    checkAge (birthdate) {
      var today = new Date();
      var yyyy = today.getFullYear();
      var age = parseInt(yyyy - birthdate.substring(0, 4));
      return age;
    },
    insertTextBoxOff (e) {
      var textBox = e.currentTarget.parentElement;
      textBox.classList.remove('on');
      textBox.parentElement.children[3].classList.remove('on');
      this.content = '';
    },
    keyEvent () {
      this.content.length === 0 ? (this.disabledUpdate = true) : (this.disabledUpdate = false);
    },
    getComment () {
      this.showUpdateComment = false;
      this.getMusicCommentByUser();
      this.getMusicCommentList();
    },
    showUpdateCommentModal () {
      $('.mo_setting_show_box').css({ bottom: '-100%', transition: 'all 1s' });
      this.showUpdateComment = true;
    },
    updateTextBoxOff (e) {
      this.isUpdateComment = false;
      var textBox = e.currentTarget.parentElement;
      textBox.classList.remove('on');
      textBox.parentElement.children[3].classList.remove('on');
      this.getMusicCommentByUser();
    },
    updateTextBoxOn (e) {
      this.isUpdateComment = true;
      var textBox = e.currentTarget.parentElement.parentElement.parentElement.parentElement;
      textBox.classList.add('on');
      textBox.parentElement.children[3].classList.add('on');
    },
    hideSetBoxMo () {
      if ($('.mo_setting_show_box').css('bottom') === '0px') {
        $('.mo_setting_show_box').css({ bottom: '-100%', transition: 'all 1s' });
      }
    },
    showSetBox (e) {
      $(e.currentTarget.parentElement).find('#setting_icon').siblings('.setting_show_box').fadeToggle();
      $('.mo_setting_show_box').css({ bottom: 0, transition: 'all 0.5s' });
    },
    textareaClickEvent (e) {
      var textBox = e.currentTarget.parentElement;
      textBox.classList.add('on');
      textBox.parentElement.children[3].classList.add('on');
    },
    removeMusicComment () {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const musicId = this.musicId;
      const userId = VueCookies.get('userId');

      axios
        .post('/api/player/removeMusicComment', { musicId, userId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.updateComment = false;
            this.content = '';
            this.getMusicCommentList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateMusicComment (e) {
      e.currentTarget.parentElement.classList.remove('on');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const musicId = this.musicId;
      const userId = VueCookies.get('userId');
      const content = this.content;

      axios
        .post('/api/player/updateMusicComment', { musicId, userId, content }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.isUpdateComment = false;
            this.updateComment = true;
            this.getMusicCommentByUser();
            this.getMusicCommentList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertMusicComment (e) {
      this.disabledInsert = true;
      e.currentTarget.parentElement.classList.remove('on');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      let encryptedMusicId = null;
      let musicId = null;
      this.music.src != null ? (encryptedMusicId = this.music.src.substring(17)) : (musicId = this.music.musicId);
      const userId = VueCookies.get('userId');
      const spaceId = VueCookies.get('spaceId');
      const content = this.content;

      axios
        .post('/api/player/insertMusicComment', { encryptedMusicId, musicId, userId, spaceId, content }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.updateComment = true;
            this.getMusicCommentByUser();
            this.getMusicCommentList();
            this.disabledInsert = false;
          }
        })
        .catch((err) => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    async getMusicCommentList () {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const limit = this.limit;
      const offset = this.offset;
      let encryptedMusicId = null;
      let musicId = null;
      this.music.src != null ? (encryptedMusicId = this.music.src.substring(17)) : (musicId = this.music.musicId);

      await axios
        .post('/api/player/selectMusicCommentListByMusic', { encryptedMusicId, musicId, limit, offset }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            this.musicCommentList = result.musicCommentList;
            this.total = result.totalCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMusicCommentByUser () {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const userId = VueCookies.get('userId');
      let encryptedMusicId = null;
      let musicId = null;
      this.music.src != null ? (encryptedMusicId = this.music.src.substring(17)) : (musicId = this.music.musicId);

      await axios
        .post('/api/player/selectMusicCommentByUser', { encryptedMusicId, musicId, userId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (result !== null) {
              this.musicId = result.musicId;
              this.content = result.content;
            }
            result === null ? (this.updateComment = false) : (this.updateComment = true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}
@media all and (max-width: 500px) {
  .modal_bottom_btn button {
    margin-bottom: 0;
  }
}
@media all and (min-width: 501px) {
  .mo_space_header {
    display: none;
  }
}
</style>
